import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./components/Home/Landing";

import "./components/styles/main.css";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Landing />} />
      </Routes>
    </Router>
  );
}

export default App;
